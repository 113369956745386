import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import '../styles/LessonsPage.css';

const Lesson1 = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked Lesson 1 Button',
      label: 'Lesson 1 Page',
    });
  }, []);

  return (
    <div className="lessons-container">
      <div className="lessons-content">
        <h1>Lesson 1: Microservices Design Principles</h1>
        <p>
          Microservices architecture is a design approach where applications are
          structured as a collection of loosely coupled, independently
          deployable services. These services are built around specific business
          capabilities and can evolve independently. Here are some fundamental
          design principles to follow when building microservices:
        </p>

        {/* Ensure High Cohesion and Low Coupling */}
        <section className="principle-section">
          <h2>1. Ensure High Cohesion and Low Coupling</h2>
          <p><strong>Explanation:</strong> Microservices should have <strong>high cohesion</strong>, meaning each service is focused on a single, well-defined business capability. 
          They should have <strong>low coupling</strong>, meaning the services should have minimal dependencies on one another.</p>

          <ul className="big-bullet">
            <li><strong>High Cohesion:</strong> A service should be responsible for a single function or closely related set of functions. 
            For example, a service responsible for user authentication should handle login, logout, token generation, and nothing more.</li>
            <li><strong>Low Coupling:</strong> Services should interact through well-defined interfaces like REST APIs or messaging systems and avoid tightly integrating with one another. 
            This helps with independent scaling, deployment, and maintenance.</li>
          </ul>

          <p><strong>Example Use Case:</strong> Consider an e-commerce platform where you have a "Payment" service and an "Order" service. 
          High cohesion ensures that the Payment service deals only with payments, while low coupling allows the Order service to operate independently, communicating with Payment via APIs. 
          Even if the Payment service is down for maintenance, the Order service can continue handling other tasks like cart management or order status tracking.</p>
        </section>

        {/* Decentralize Data Management */}
        <section className="principle-section">
          <h2>2. Decentralize Data Management</h2>
          <p><strong>Explanation:</strong> In monolithic applications, you might have a single database shared by multiple components. 
          In microservices, each service should ideally manage its own <strong>database</strong> or <strong>data store</strong>. 
          This decentralization reduces dependencies between services and ensures that each service is responsible for its own data consistency.</p>

          <p><strong>Example Use Case:</strong> Imagine a healthcare system where patient data, appointment scheduling, and billing services each use different databases. 
          This separation ensures that changes in one service (e.g., updating the billing logic) don’t affect the others, improving resilience and performance.</p>
        </section>

        {/* Design for Failure and Resilience */}
        <section className="principle-section">
          <h2>3. Design for Failure and Resilience</h2>
          <p><strong>Explanation:</strong> In a distributed microservices system, individual services can fail for various reasons (network issues, hardware failure, etc.). 
          Microservices need to be <strong>resilient</strong> by anticipating and gracefully handling failures. 
          Use patterns like <strong>circuit breakers, timeouts</strong>, and <strong>retries</strong> to manage faults effectively.</p>

          <p><strong>Example Use Case:</strong> Consider a logistics application where a service that tracks delivery statuses fails. 
          A circuit breaker can stop other services (like the customer notification service) from overwhelming it with requests and can trigger an alert so that the operations team can investigate.</p>
        </section>

        {/* API First Approach */}
        <section className="principle-section">
          <h2>4. API First Approach</h2>
          <p><strong>Explanation:</strong> Every microservice should expose its functionality through a well-defined API. 
          The <strong>API first</strong> approach ensures that services can communicate with each other and with external clients in a standardized way, regardless of the underlying technologies or languages used to build them.</p>

          <p><strong>Example Use Case:</strong> A financial application can have a <strong>Currency Conversion</strong> service that exposes an API endpoint like 
          <span className="highlighted-text">/convert?from=USD&to=EUR&amount=100</span>. 
          This endpoint can be used by other services (e.g., a payments service) without needing to know how the conversion logic works internally.</p>
        </section>

        {/* Adhere to the Single Responsibility Principle */}
        <section className="principle-section">
          <h2>5. Adhere to the Single Responsibility Principle</h2>
          <p><strong>Explanation:</strong> Each microservice should have a <strong>single responsibility</strong> and address one specific business capability. 
          The <strong>Single Responsibility Principle</strong> (SRP) helps keep services focused and simple to understand, maintain, and evolve. 
          It reduces the risk of one service change affecting another, as each service operates within its own well-defined scope.</p>

          <p><strong>Example Use Case:</strong> A <strong>Notification Service</strong> in an e-commerce application should handle only tasks related to sending emails, SMS, or push notifications. 
          It should not be responsible for other unrelated operations, such as user authentication or payment processing.</p>
        </section>

        {/* Define the Scope Properly */}
        <section className="principle-section">
          <h2>6. Define the Scope Properly</h2>
          <p><strong>Explanation:</strong> Clearly <strong>defining the boundaries</strong> of each microservice is critical. Overlapping responsibilities between services can lead to tight coupling, while unclear boundaries can cause confusion during development and maintenance. 
          Each microservice should have a clear purpose, and you should avoid "scope creep" where a service's responsibilities expand beyond its initial intent.</p>

          <p><strong>Example Use Case:</strong> In a video streaming platform, you may have separate services for <strong>content management, user subscriptions</strong>, and <strong>recommendation engines</strong>. 
          Defining the scope ensures the recommendation engine only handles suggestions based on user behavior, leaving content creation and user authentication to other dedicated services.</p>
        </section>

        {/* Use Event-Driven Communication When Necessary */}
        <section className="principle-section">
          <h2>7. Use Event-Driven Communication When Necessary</h2>
          <p><strong>Explanation:</strong> Microservices should communicate asynchronously whenever possible to avoid direct dependencies between services. 
          This can be achieved through <strong>event-driven architectures</strong>, where services publish and subscribe to events. 
          For example, when an order is placed, an "Order Placed" event can trigger actions in multiple other services, like updating the inventory, processing payment, and sending notifications.</p>

          <p><strong>Example Use Case:</strong> In a food delivery system, when a new order is placed, an event is published. 
          The restaurant service listens to this event and updates the kitchen order list, while the delivery service prepares to assign a courier.</p>
        </section>

        {/* Secure Each Service Individually */}
        <section className="principle-section">
          <h2>8. Secure Each Service Individually</h2>
          <p><strong>Explanation:</strong> Each microservice should be secured individually with authentication and authorization mechanisms. 
          <strong>OAuth 2.0</strong> or <strong>JWT tokens</strong> are commonly used for ensuring that only authenticated requests are processed. 
          Every microservice should verify the token before performing any action.</p>

          <p><strong>Example Use Case:</strong> In a banking application, both the account service and transaction service should require token validation. 
          A user requesting transaction details must have the correct permissions (based on the token) before any data is retrieved.</p>
        </section>

        {/* Scalability and Independence */}
        <section className="principle-section">
          <h2>9. Scalability and Independence</h2>
          <p><strong>Explanation:</strong> One of the main advantages of microservices is that they can be <strong>scaled independently</strong> based on the demand. 
          Services that require more resources, such as the search service in an e-commerce platform, can be scaled up without affecting other services.</p>

          <p><strong>Example Use Case:</strong> An online gaming platform may need to scale its matchmaking service during peak hours but doesn’t need to scale the profile management service at the same time.</p>
        </section>

        {/* Automate Testing and Continuous Deployment (CI/CD) */}
        <section className="principle-section">
          <h2>10. Automate Testing and Continuous Deployment (CI/CD)</h2>
          <p><strong>Explanation:</strong> Microservices should have automated <strong>unit tests, integration tests</strong>, and <strong>end-to-end tests</strong> to ensure each service functions correctly on its own and when integrated with others. 
          This, combined with <strong>continuous integration/continuous deployment (CI/CD)</strong> pipelines, allows for frequent updates and ensures that changes in one service don't break others.</p>

          <p><strong>Example Use Case:</strong>  In a ride-sharing application, suppose a developer updates the fare calculation logic. 
          An automated test suite can immediately check if this update impacts other services (like the payments or trip-scheduling services), ensuring that any issues are caught early before deployment.</p>
        </section>

        {/* Manage Traffic */}
        <section className="principle-section">
          <h2>11. Manage Traffic</h2>
          <p><strong>Explanation:</strong> In a microservices architecture, where multiple services communicate over a network, handling <strong>traffic</strong> efficiently is essential. 
          This can be achieved using techniques like <strong>rate limiting, load balancing</strong>, and <strong>circuit breakers</strong> to prevent individual services from being overwhelmed by high traffic.</p>

          <p><strong>Example Use Case:</strong> In a <strong>news website</strong> that experiences traffic spikes during breaking news, you can use <strong>load balancing</strong> to distribute traffic evenly among multiple instances of a <strong>Content Service</strong>, preventing any single instance from becoming a bottleneck.</p>
        </section>

        {/* Monitor Constantly */}
        <section className="principle-section">
          <h2>12. Monitor Constantly</h2>
          <p><strong>Explanation:</strong> Constant monitoring of each microservice is key to ensuring <strong>performance, reliability</strong>, and <strong>availability</strong>. 
          With distributed systems, it’s important to track metrics like CPU usage, memory consumption, and error rates. Monitoring tools such as <strong>Prometheus, Grafana</strong>, and <strong>ELK Stack</strong> provide insights into the health of your services.</p>

          <p><strong>Example Use Case:</strong> In a <strong>ride-hailing app</strong>, tracking service latency is critical. A slow <strong>Booking Service</strong> can result in a poor user experience. 
          By monitoring metrics and setting up alerts, you can quickly identify and resolve issues before they impact customers.</p>
        </section>

        {/* Conclusion */}
        <section className="principle-section">
          <h2>Conclusion</h2>
          <p>By following these 12 principles, your microservices architecture will be well-structured, scalable, and resilient.</p>
        </section>

        {/* Redirections */}
        <a style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }} href="/lessons">Back To Lessons</a>
        <a style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)', marginLeft: '30px' }} href="/lessons/lesson2">Next Lesson</a>
        
      </div>
    </div>
  );
};

export default Lesson1;
