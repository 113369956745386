import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import { UserProvider } from './contexts/UserContext'; // Import UserProvider for context

const MEASUREMENT_ID = 'G-8XRFBBHM6Y'; // Replace with your GA Measurement ID
const root = ReactDOM.createRoot(document.getElementById('root'));

ReactGA.initialize(MEASUREMENT_ID);

root.render(
  <React.StrictMode>
    <UserProvider> {/* Wrap the app with UserProvider */}
      <App />
    </UserProvider>
  </React.StrictMode>
);

// Measure app performance
reportWebVitals();
