import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

export const UserContext = createContext();

// Move this function outside the component to avoid dependency warnings
const validateToken = async (token) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/auth/validate-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    const data = await response.json();
    if (response.ok && data) {
      return true;
    }
  } catch (error) {
    console.error("Error validating session: ", error);
  }
  return false;
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);  // New isUserAdmin state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');
  
    const isTokenValid = (storedToken) => {
      try {
        const decoded = jwtDecode(storedToken);
        const now = Date.now() / 1000; // in seconds
        return decoded.exp > now; // true if token has not expired
      } catch (error) {
        return false; // invalid token format
      }
    };

    if (storedToken && isTokenValid(storedToken)) {
      // Token is valid; skip backend validation
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      setIsLoggedIn(true);
      setLoading(false);
      // Check if user is an admin based on the role field
      if (parsedUser.role === "admin") {
        setIsUserAdmin(true);
      }
    } else if (storedToken && !isTokenValid(storedToken)) {
      // Token might be close to expiry; validate with backend
      validateToken(storedToken).then(isValid => {
        setIsLoggedIn(isValid);
      });
    } else {
      setIsLoggedIn(false);
    }
    
    // Set loading to false once the check is done
    setLoading(false);    
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, isLoggedIn, setIsLoggedIn, isUserAdmin, loading }}>
      {children}
    </UserContext.Provider>
  );
};

