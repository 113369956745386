import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/LessonsPage.css';
import ReactGA from 'react-ga4';

const LessonsPage = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked Lessons Button',
      label: 'Lessons Page',
    });
  }, []);
  return (
    <div className="lessons-container">
      <div className="lessons-content">
        <h1>Software Architecture Almanac</h1>
        <p><b style={{ fontStyle: 'italic' }}>Software Architecture Almanac with Bhuvnesh Arya</b> is a free resource offering in-depth lessons on various aspects of software architecture. Each lesson explores key topics such as application design, system integration, enterprise architecture, best practices, and more, helping you deepen your understanding and enhance your skills. </p>
        <ul className="lessons-list">
          <li><Link to="/lessons/lesson3">Lesson 3: Domain-Driven Design - Microservices Style (Dec 13, 2024)</Link></li>
          <li><Link to="/lessons/lesson2">Lesson 2: System Design Framework (Nov 2, 2024)</Link></li>
          <li><Link to="/lessons/lesson1">Lesson 1: Microservices Design Principles (Oct 5, 2024)</Link></li>
          {/* Add more lessons as needed */}
        </ul>
      </div>
    </div>
  );
};

export default LessonsPage;
