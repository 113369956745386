// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import BlogsPage from './pages/BlogsPage';
import BlogSubmissionPage from './pages/BlogSubmissionPage';
import AboutMePage from './pages/AboutMePage';
import ContactMePage from './pages/ContactMePage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import MyAccount from './profile/MyAccount';
import LessonsPage from './pages/LessonsPage';
import Lesson1 from './lessons/Lesson1';
import Lesson2 from './lessons/Lesson2';
import Lesson3 from './lessons/Lesson3';
import PrivateRoute from './authentication/PrivateRoute';
import AdminRoute from './authentication/AdminRoute';
import ResourcesPage from './pages/ResourcesPage';
import BlogDetailPage from './blogs/BlogDetailPage';
import MyBlogs from './profile/MyBlogs';
import ReviewBlogs from './profile/ReviewBlogs';
import ManageUsers from './profile/ManageUsers';
import CodeInsightsPage from './pages/CodeInsightsPage';
import EmailSubscriptionPage from './pages/EmailSubscriptionPage';
import SendLessonEmail from './profile/SendLessonEmail';

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<Navigate to="/" />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/write" element={<PrivateRoute><BlogSubmissionPage /></PrivateRoute>} />
        <Route path="/about-me" element={<AboutMePage />} />
        <Route path="/contact" element={<ContactMePage />} />
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/subscribe" element={<EmailSubscriptionPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/code-insights" element={<CodeInsightsPage />} />
        
        <Route path="/blogs/author/:urlAuthor" element={<BlogsPage />} /> 
        <Route path="/blogs/tag/:urlCategory" element={<BlogsPage />} />
        <Route path="/blogs/content/:urlTitle" element={<BlogDetailPage />} />
        <Route path="/blogs/review/:urlTitle" element={<BlogDetailPage />} />
        <Route path="/resources" element={<ResourcesPage />} />  
        <Route path="/my-blogs" element={<MyBlogs />} />
        <Route path="/manage-users" element={<AdminRoute><ManageUsers /></AdminRoute>} />
        <Route path="/review-blogs" element={<AdminRoute><ReviewBlogs /></AdminRoute>} />
        <Route path="/send-lesson-email" element={<AdminRoute><SendLessonEmail /></AdminRoute>} />

        <Route path="/lessons" element={<LessonsPage />} />
        <Route path="/lessons/lesson1" element={<Lesson1 />} />
        <Route path="/lessons/lesson2" element={<Lesson2 />} />
        <Route path="/lessons/lesson3" element={<Lesson3 />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
