import React, { useEffect, useState, useRef, useContext } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { UserContext } from '../contexts/UserContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/BlogSubmissionPage.css';

const sanitizeQuillContent = (htmlContent) => {
  // Create a temporary div to parse the HTML content
  const div = document.createElement('div');
  div.innerHTML = htmlContent;

  // Remove unwanted span elements with class 'ql-ui'
  const spans = div.querySelectorAll('span.ql-ui');
  spans.forEach((span) => span.remove());

  // Process all ordered lists (ol)
  const orderedLists = div.querySelectorAll('ol');
  orderedLists.forEach((ol) => {
    // Process all list items (li) inside an ordered list (ol)
    const listItems = ol.querySelectorAll('li[data-list="ordered"]');
    listItems.forEach((li) => {
      li.removeAttribute('data-list'); // Remove Quill's internal data-list attribute
    });
  });

  // Process all unordered lists (ul)
  const unorderedLists = div.querySelectorAll('ul');
  unorderedLists.forEach((ul) => {
    // Process all list items (li) inside an unordered list (ul)
    const listItems = ul.querySelectorAll('li[data-list="bullet"]');
    listItems.forEach((li) => {
      li.removeAttribute('data-list'); // Remove Quill's internal data-list attribute
    });
  });

  // Return the sanitized HTML
  return div.innerHTML;
};



const BlogEditor = ({ quillRef, content, setContent }) => {
  const editorRef = useRef(null);
  const maxContentLength = 20000;

  useEffect(() => {
    // Initialize Quill editor when entering edit mode
    if (!quillRef.current) {
      // Condition 1: Initialize Quill editor if active and not yet created
      quillRef.current = new Quill(editorRef.current, {
        theme: 'snow',
        placeholder: 'Write your blog here...',
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            ['link', 'image'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean'],
          ],
        },
      });
    
      // Sync content with state
      quillRef.current.on('text-change', () => {
        const htmlContent = quillRef.current.root.innerHTML;
        const sanitizedContent = sanitizeQuillContent(htmlContent);
        if (sanitizedContent.length <= maxContentLength) {
          setContent(sanitizedContent);
        }
      });
    
      // Set initial content in editor
      if (content !== quillRef.current.root.innerHTML) {
        const sanitizedContent = sanitizeQuillContent(content);
        quillRef.current.root.innerHTML = sanitizedContent;
      }
    
    }
    
  }, [content, setContent, quillRef]);

  return (
    <div>
      <label>
        Blog Content (Characters Written: {content.length}/{maxContentLength}):
      </label>
      <div ref={editorRef} style={{ height: '300px', marginBottom: '10px' }} />
    </div>
  );
};


const BlogSubmissionPage = () => {
  const { user } = useContext(UserContext);
  const quillRef = useRef(null);
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const maxTitleLength = 120;

  const backendUrl = `${process.env.REACT_APP_USER_SERVICE_API_URL}/api`; 

  const convertToSlug = (text) =>
    text
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9\s-]/g, '')
      .replace(/\s+/g, '-');

  const handleSubmit = async (status) => {
    if (!category || !title || !content.trim()) {
      setErrorMessage('All fields are required.');
      return;
    }

    setIsSubmitting(true);
    console.log("blog submission");
    console.log(content);
    console.log(sanitizeQuillContent(content));
    const sanitizedContent = sanitizeQuillContent(content);
    try {
      const response = await axios.post(
        `${backendUrl}/blogs`,
        {
          title,
          author: `${user.firstName} ${user.lastName}`,
          content: sanitizedContent,  // Save the sanitized content
          category,
          urlTitle: convertToSlug(title),
          urlCategory: convertToSlug(category),
          urlAuthor: user.username,
          status,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );

      if (response.status === 200) {
        alert(
          'Your blog has been submitted for review and is currently pending approval. In the meantime, feel free to explore other articles on the site!'
        );
        navigate('/blogs');
      }
    } catch (error) {
      setErrorMessage('Error submitting blog.');
      console.error(error);
    }
    setIsSubmitting(false);
  };

  return (
    <div className="blogs-submission-page-container">
      <div className="blogs-submission-page-content">
        <h1>Write a Blog</h1>
        <select value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value="">Select a category</option>
          <option value="Software Engineering">Software Engineering</option>
          <option value="Data Engineering">Data Engineering</option>
          <option value="Machine Learning">Machine Learning</option>
          <option value="Cloud Computing">Cloud Computing</option>
          <option value="Artificial Intelligence">Artificial Intelligence</option>
          <option value="DevOps">DevOps</option>
          <option value="Web Development">Web Development</option>
        </select>
        {errorMessage && <p className="blog-error-message">{errorMessage}</p>}

        <div>
          <label>
            Title (Characters Written: {title.length}/{maxTitleLength}):
          </label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
            maxLength={maxTitleLength}
            placeholder="Enter blog title"
          />
        </div>

        <BlogEditor
            quillRef={quillRef}
            content={content}
            setContent={setContent}
          />

        <div className="blog-submission-buttons">
          <button
            onClick={() => handleSubmit('pending')}
            disabled={isSubmitting}
            className="submit-approval-button"
          >
            Submit for Approval
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogSubmissionPage;
