import React, { useEffect } from 'react';
import '../styles/Introduction.css'; // Create a CSS file for styling
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Introduction = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Introduction Section',
      label: 'Introduction Section',
    });
  }, []);
  return (
    <div className="intro-container">
      <div className="intro-content">
        <div className="intro-image">
            <img src="/images/bhuvnesh.avif" alt="Bhuvnesh Arya" />
        </div>
        <h1>Insights That Transform Your Software Journey!</h1>
        <p1>
          “Insight Veda is your go-to platform for mastering software engineering and architecture. With actionable feedback, curated resources, and in-depth lessons on software architecture, system design, and emerging technologies, it empowers developers, professionals, technical leads, and architects to excel and make a lasting impact in their fields.”
        </p1>
        <p>
          <Link style={{color: '#0069d2'}} to="/about-me"><b>Bhuvnesh Arya</b></Link>, Software Architect
        </p>
      </div>
      
    </div>
  );
}

export default Introduction;
