import React, { useEffect } from 'react';
import '../styles/Introduction.css'; // Create a CSS file for styling
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Lessons = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked Lessons Button',
      label: 'Lessons Page',
    });
  }, []);
  return (
    <div className="intro-container">
      <div className="intro-content">
      <div className="intro-image">
            <a href="/lessons"><img src="/images/lessons.avif" alt="Lessons" href="/lessons" /></a>
        </div>
      <div className="home-container">
      <p><Link style={{color: '#0069d2'}} to="/lessons"><b>Software Architecture Almanac</b></Link>  offers lessons on application design, integration strategies, enterprise architecture, and best practices. Each lesson provides practical insights to advance your architectural journey and deepen your knowledge.</p> 
    </div>
      </div>
      
    </div>
  );
}

export default Lessons;
