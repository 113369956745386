import React, { useEffect } from 'react';
import '../styles/Introduction.css'; // Create a CSS file for styling
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Blogs = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Blogs Section',
      label: 'Blogs Section',
    });
  }, []);
  return (
    <div className="intro-container">
      <div className="intro-content">
      <div className="intro-image">
            <a href="/blogs"><img src="/images/blogs.avif" alt="Blogs" href="/blogs" /></a>
        </div>
      <div className="home-container">
      <p>The <Link style={{color: '#0069d2'}} to="/blogs"><b>Blogs</b></Link> page features a curated collection of articles from the developer community on software engineering, data engineering, system design, and emerging technologies. Each post offers valuable insights, best practices, and practical examples to help enhance your skills. </p> 
    </div>
      </div>
      
    </div>
  );
}

export default Blogs;