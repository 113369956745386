import React, { useEffect } from 'react';
import '../styles/ContactMePage.css'; // Add styling as necessary
import ReactGA from 'react-ga4';

const ContactMePage = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked ContactMe Button',
      label: 'ContactMe Page',
    });
  }, []);
  return (
    <div className="contact-me-container">
        <div className="contact-me-card">
            <p>For any inquiries or consultation session, feel free to reach out at:</p>
            <div className="contact-details">
                <p><strong>Email:</strong> <a href="mailto:info@insightveda.com" target="_blank" className="social-link email" rel="noopener noreferrer">info@insightveda.com</a></p>
                <p><strong>1:1 Connect:</strong> <a href="https://topmate.io/bhuvnesh_arya" target="_blank" className="social-link email" rel="noopener noreferrer">https://topmate.io/bhuvnesh_arya</a></p>
            </div>
        </div>
    </div>
  );
};

export default ContactMePage;
