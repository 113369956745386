import React, { useEffect } from 'react';
import '../styles/Introduction.css'; // Create a CSS file for styling
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const CodeInsights = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked Lessons Button',
      label: 'Lessons Page',
    });
  }, []);
  return (
    <div className="intro-container">
      <div className="intro-content">
      <div className="intro-image">
            <a href="/code-insights"><img src="/images/code-insights.avif" alt="Code Insights" href="/code-insights" /></a>
        </div>
      <div className="home-container">
      <p><Link style={{color: '#0069d2'}} to="/code-insights"><b>Code Insights</b></Link> is an AI-powered tool that provides in-depth analysis of your codebase, focusing on critical aspects such as quality, performance, security, maintainability, and scalability. Leverage advanced AI technology to gain deep insights into architectural feedback, testing coverage, adherence to best practices, and areas for improvement.
      </p> 
    </div>
      </div>
      
    </div>
  );
}

export default CodeInsights;
