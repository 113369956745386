import React, { useEffect } from 'react';
import '../styles/Introduction.css';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Resources = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Resources Section',
      label: 'Resources Section',
    });
  }, []);

  return (
    <div className="intro-container">
      <div className="intro-content">
        <div className="intro-image">
          <a href="/resources"><img src="/images/resources.avif" alt="Resources" /></a>
        </div>
        <div className="home-container">
          <p>
            The <Link style={{color: '#0069d2'}} to="/resources"><b>Resources</b></Link> page features a curated collection of top external links on software architecture, system design, big data, development roadmaps and more. It offers handpicked articles and research papers to help you stay updated and expand your knowledge in key technology areas.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Resources;
