import React, { useState, useEffect } from 'react';
import '../styles/EmailSubscriptionPage.css';
import ReactGA from 'react-ga4';
import axios from 'axios';

const EmailSubscriptionPage = () => {
  const [email, setEmail] = useState('');
  const [validationCode, setValidationCode] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Email Subscription Page',
      label: 'Email Subscription Page',
    });
  }, []);

  const isValidEmail = (email) => {
    const emailRegex = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleSubscription = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    if (!isValidEmail(email)) {
      setErrorMessage('Invalid email format. Please enter a valid email address.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/subscriptions/subscribe`, { email });
      if (response.status === 200) {
        setSubmitted(true);
        setSuccessMessage("A verification code has been sent to your email. Please check your inbox and, if you don't see it, check your spam or junk folder as well.");
      }
    } catch (error) {
      console.error('Error subscribing:', error);
      setErrorMessage('Failed to subscribe. Please try again.');
    }
  };

  const handleValidation = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const response = await axios.post(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/subscriptions/validate`, { email, validationCode });
      if (response.status === 200) {
        setIsVerified(true);
        setSuccessMessage('🎉 You have successfully subscribed!');
      }
    } catch (error) {
      console.error('Error validating subscription:', error);
      setErrorMessage('Validation failed. Please check the code and try again.');
    }
  };

  return (
    <div className="email-subscription-container">
      <div className="email-subscription-content">
        <h1>Email Subscription</h1>
        <p>Stay updated with the latest lessons, articles, and insights delivered straight to your inbox.</p>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}

        {!submitted && !isVerified && (
          <form onSubmit={handleSubscription} className="subscribe-form">
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
            <div className="email-subscribe-container">
              <button type="submit" className="email-subscribe-button">Subscribe</button>
            </div>
          </form>
        )}

        {submitted && !isVerified && (
          <form onSubmit={handleValidation} className="validation-form">
            <input
              type="text"
              name="validationCode"
              value={validationCode}
              onChange={(e) => setValidationCode(e.target.value)}
              placeholder="Enter validation code"
              required
            />
            <div className="email-validation-container">
              <button type="submit" className="email-validation-button">Validate</button>
            </div>
          </form>
        )}

        
      </div>
    </div>
  );
};

export default EmailSubscriptionPage;
