import React, { useEffect } from 'react';
import IntroductionSection from '../sections/IntroductionSection'; // Adjust the path as needed
import '../styles/HomePage.css'; // Make sure to create a corresponding CSS file
import ReactGA from 'react-ga4';
import CodeInsightsSection from '../sections/CodeInsightsSection';
import LessonsSection from '../sections/LessonsSection';
import BlogsSection from '../sections/BlogsSection';
import ResourcesSection from '../sections/ResourcesSection';

const HomePage = () => {
    useEffect(() => {
      ReactGA.event({
        category: 'User Interaction',
        action: 'Clicked HomePage Button',
        label: 'Home Page',
      });
    }, []);
    return (
      <div className="homepage">
        <div className="hero-section">
          <div className="hero-content">
            <div className="hero-header"> Insight Veda </div>
            <div className="hero-tagline"> 
              Turning <div className="hero-tagline-highlight"> Knowledge </div> 
            </div>
            <div className="hero-tagline">
              into impactful growth!
            </div>
            <div className="hero-subline-1">Explore <b>Coding</b> insights, <b>Software Architecture</b> lessons, and <b>Resources</b> to elevate your software journey. </div>
          </div>
        </div>
        <IntroductionSection />
        <CodeInsightsSection />
        <LessonsSection />
        <BlogsSection />
        <ResourcesSection />
      </div>
    );
  }

export default HomePage;
