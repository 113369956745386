import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../styles/BlogDetailPage.css';

const BlogDetailPage = () => {
  const { urlTitle } = useParams(); // Get the blog urlTitle from URL parameters
  const [blog, setBlog] = useState(null);
  const [error, setError] = useState(null);

  const convertSanitizedToQuillHTML = (sanitizedHtml) => {
    const div = document.createElement('div');
    div.innerHTML = sanitizedHtml;
  
    // Get all list items
    const listItems = div.querySelectorAll('li');
  
    // We need to maintain both ordered and unordered list elements in the same sequence as input
    listItems.forEach((li) => {
      // Check if this list item should be a bullet (unordered) list item
      const isBullet = li.hasAttribute('data-list') && li.getAttribute('data-list') === 'bullet';
  
      // If the list item has the bullet attribute (unordered), move it to an unordered list
      if (isBullet) {
        // If the item is part of a bullet list, ensure it is in a <ul> and not <ol>
        if (!li.parentElement || li.parentElement.tagName !== 'UL') {
          const ul = document.createElement('ul');
          li.parentElement.insertBefore(ul, li);
          ul.appendChild(li); // Move the li to the new ul
        }
      } else {
        // Otherwise, it's part of an ordered list, ensure it stays inside <ol>
        if (!li.parentElement || li.parentElement.tagName !== 'OL') {
          const ol = document.createElement('ol');
          li.parentElement.insertBefore(ol, li);
          ol.appendChild(li); // Move the li to the new ol
        }
      }
    });
  
    // Return the updated HTML preserving the structure
    return div.innerHTML;
  };
  
  

  useEffect(() => {

    if (!urlTitle) {
      console.error("urlTitle is undefined");
      setError("Blog URL is invalid.");
      return;
    }

    const fetchBlog = async () => {
      try {
        // Check if we are in review mode or content mode
        let response;
        if (window.location.pathname.includes('/review/')) {
          // Fetch blog in review mode
          response = await axios.get(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/blogs/detail/${urlTitle}/review`);
        } else {
          // Fetch only approved blog
          response = await axios.get(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/blogs/detail/${urlTitle}`);
        }

        if (response.data) {
            setBlog(response.data);
          } else {
            setError("This blog is currently unavailable. It may still be awaiting approval or hasn't been created yet.");
          }
       
      } catch (err) {
        setError('Error fetching blog');
      }
    };

    fetchBlog();
  }, [urlTitle]);

  if (error) {
    return <p>{error}</p>;
  }

  if (!blog) {
    return <p>Loading...</p>;
  }

  console.log(blog.content);
  console.log(convertSanitizedToQuillHTML(blog.content));
  return (
    <div className="blogs-page-container">
      <section className="blogs-detail-content">
        <h1>{blog.title}</h1>
        <p style={{ 'textAlign' : 'left' }}><b>Author:</b> {blog.author}</p>
        <p style={{ 'textAlign' : 'left' }}><b>Date:</b> {new Date(blog.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
        <div dangerouslySetInnerHTML={{ __html: convertSanitizedToQuillHTML(blog.content) }}></div>
        <a style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '1rem', color: 'rgb(0, 84, 147)' }} href="/blogs">Back To Blogs</a>
      </section>
      
    </div>
  );
};

export default BlogDetailPage;
