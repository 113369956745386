import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../styles/BlogsPage.css';
import ReactGA from 'react-ga4';
import { format } from 'date-fns';


const BlogsPage = () => {
  const [blogs, setBlogs] = useState([]);  // State to store blogs data
  const [apiError, setApiError] = useState('');
  const [urlAuthorFilter, setUrlAuthorFilter] = useState(null);  // State for the selected author
  const [urlCategoryFilter, setUrlCategoryFilter] = useState(null);  // State for the selected category

  const navigate = useNavigate();
  const { urlAuthor, urlCategory } = useParams();

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'MMMM d, yyyy');
  };

  const fetchBlogsData = async (urlAuthor = null, urlCategory = null) => {
    try {
      let url = `${process.env.REACT_APP_USER_SERVICE_API_URL}/api/blogs/approved`;

      const queryParams = [];
      if (urlAuthor) {
        queryParams.push(`urlAuthor=${encodeURIComponent(urlAuthor)}`);
      } else if (urlCategory) {
        queryParams.push(`urlCategory=${encodeURIComponent(urlCategory)}`);
      }

      if (queryParams.length > 0) {
        url += `?${queryParams.join('&')}`;
      }

      const response = await fetch(url);
      if (!response.ok) {
        setApiError('Error in fetching blogs data. Please try again.');
      }
      const data = await response.json();
      setBlogs(data);
    } catch (error) {
      setApiError(error.message);
    }
  };

  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked BlogsPage Button',
      label: 'Blogs Page',
    });

    if (urlAuthor) {
      setUrlAuthorFilter(urlAuthor);
      setUrlCategoryFilter(null);
      fetchBlogsData(urlAuthor, null);
    } else if (urlCategory) {
      setUrlCategoryFilter(urlCategory);
      setUrlAuthorFilter(null);
      fetchBlogsData(null, urlCategory);
    } else {
      fetchBlogsData();
    }
  }, [urlAuthor, urlCategory]); // Re-run when URL params change

  const handleUrlAuthorClick = (urlAuthor) => {
    console.log(urlAuthorFilter);
    setUrlAuthorFilter(urlAuthor);
    setUrlCategoryFilter(null);
    fetchBlogsData(urlAuthor, null);
    navigate(`/blogs/author/${urlAuthor}`);
  };

  const handleUrlCategoryClick = (urlCategory) => {
    console.log(urlCategoryFilter);
    setUrlCategoryFilter(urlCategory);
    setUrlAuthorFilter(null);
    fetchBlogsData(null, urlCategory);
    navigate(`/blogs/tag/${urlCategory}`);
  };

  const handleViewAllClick = () => {
    console.log(apiError);
    setUrlCategoryFilter(null);
    setUrlAuthorFilter(null);
    fetchBlogsData(null, null);
    navigate(`/blogs`);
  };

  return (
    <div className="blogs-page-container">
      <section className="blogs-content">
        <h1>All Articles</h1>
        <p>Discover a <b style={{ fontStyle: 'italic' }}>collection of insightful articles</b> generated by the developer community, covering software engineering, data engineering, system design, AI, cloud and the latest technologies. </p>
        <span
            role="button"
            onClick={handleViewAllClick}
            style={{ textDecoration: 'underline', cursor: 'pointer', color: 'rgb(0, 84, 147)', marginBottom:'20px', fontSize: '1rem' }}
          >
            View All
          </span>
        <ul className="blogs-list">
          

          {blogs.length > 0 ? (
            blogs.map((blog, index) => (
              <div key={index} className="blog-item">
                <ul className="blogs-content">
                  <li><Link to={`/blogs/content/${blog.urlTitle}`}>{blog.title}</Link></li>
                  <p1>
                    Tag <span
                      role="button"
                      onClick={() => handleUrlCategoryClick(blog.urlCategory)}
                      style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '0.8rem', color: 'rgb(0, 84, 147)' }}
                    >
                      {blog.category}
                    </span> | 
                    By <span
                      role="button"
                      onClick={() => handleUrlAuthorClick(blog.urlAuthor)}
                      style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '0.8rem', color: 'rgb(0, 84, 147)' }}
                    >
                      {blog.author}
                    </span> | {formatDate(blog.createdAt)}
                  </p1>
                </ul>
              </div>
            ))
          ) : (
            <p>No blogs available yet. Please check back later.</p>
          )}
        </ul>
      </section>
    </div>
  );
};

export default BlogsPage;
