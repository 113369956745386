import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { UserContext } from '../contexts/UserContext';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import '../styles/CodeInsightsPage.css';
import GaugeChart from 'react-gauge-chart';
import ReactGA from 'react-ga4';

// Register required Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CodeInsightsPage = () => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [apiError, setApiError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { isLoggedIn } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visited CodeInsights Page',
      label: 'CodeInsights Page',
    });
  }, []);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setApiError('');
    setSuccessMessage('');
    setData(null); // Reset data when a new file is selected
  };

  const handleShowAnalysis = async () => {
    if (!isLoggedIn) {
      navigate('/sign-in');
      return;
    }

    if (!file) {
      setApiError('Please select a file to analyze.');
      return;
    }
  
    setIsLoading(true); // Start the loading spinner
    setApiError('');
    setSuccessMessage('');
  
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('file', file);
  
      const response = await fetch(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/code-review/analyze`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Handle rate limiting (status 429)
      if (response.status === 429) {
        let errorMessage = 'You have reached your daily scan limit. Please try again tomorrow.';
        
        // Attempt to read the response as text (since it is plain text)
        const text = await response.text();
        errorMessage = text || errorMessage; // If response.text() is empty, fallback to default message
  
        // Throw error with the appropriate message
        throw new Error(errorMessage);
      }
  
      // Check if the response is ok (status 2xx)
      if (!response.ok) {
        throw new Error('Failed to retrieve analysis data.');
      }
  
      // Parse the response as JSON
      const result = await response.json();
      setData(result); // Store the analysis data
    } catch (error) {
      setApiError(error.message); // Display error message
    } finally {
      setIsLoading(false); // Stop the loading spinner
    }
  };
  
  // Define colors for the gauge sections (Red, Yellow/Orange, Green)
  const colors = ['#FF0000', '#FFA500', '#FFFF00', '#00FF00']; // Red, Orange, Yellow, Green


  return (
    <div className="code-insights-page-container">
      <div className="code-insights-content">
        <h1>Code Insights</h1>
        <p>Discover <b style={{ fontStyle: 'italic' }}>actionable insights into your codebase</b> with our AI-powered analysis tools. Simply upload a ZIP file containing your source code and click 'Show Analysis' to reveal a comprehensive breakdown of your code quality, performance, security, maintainability, and other critical aspects. </p>
        <p><strong>File Upload Instructions:</strong></p>
        <ul>
          <li>The maximum file size limit is <strong>5 MB.</strong></li>
          <li><strong>Larger files</strong> may take a little longer to process, so please be patient while we analyze your code.</li>
          <li><strong>Supported Programming Languages:</strong> Java, Python, Ruby, PHP, JavaScript, TypeScript, Swift, Kotlin, Go, Rust, and Scala.</li>
          <li>Daily Scan limit: <strong>3 scans</strong> per day</li>
        </ul>

        {!isLoggedIn && (
          <div className="code-insights-signin-container">
          <button type="submit" className="code-insights-signin-button" onClick={() => navigate('/sign-in')}>Sign in to Continue</button>
          </div>
        )}
        
        {isLoggedIn && (
          <>
        
        {apiError && <div style={{ color: 'red' }}>{apiError}</div>}
        {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}

        {/* File Input Embedded in Text Field */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '16px' }}>
          <TextField
            variant="outlined"
            fullWidth
            value={file ? file.name : ''}
            placeholder="Select a zip file..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="contained" component="label">
                    Select File
                    <input type="file" accept=".zip" hidden onChange={handleFileChange} />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <Button className="show-analysis-button"
            variant="contained"
            onClick={handleShowAnalysis}
            disabled={isLoading || !file}
            startIcon={isLoading && <CircularProgress size={20} color="inherit" />}
          >
            {isLoading ? 'Analyzing...' : 'Show Analysis'}
          </Button>
        </Box>
        </>
        )}

        {/* Analysis Dashboard */}
        {data && (
          <Box sx={{ padding: '16px' }}>
            <Grid container spacing={3} alignItems="stretch">
              {/* Overall Score */}
              <Grid item xs={12}>
                <Card>
                  <CardContent style={{ height: '100%', position: 'relative' }}>
                    <Typography
                      variant="h2"
                      style={{
                        position: 'absolute',
                        top: '10px',
                        left: '20px',
                        margin: 0,
                      }}
                    >
                      <strong>Overall Score</strong>
                    </Typography>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <GaugeChart
                        id="gauge-chart"
                        nrOfLevels={30}
                        colors={colors}
                        percent={(data.compositeScore / 10) * 100 / 100}
                        arcWidth={0.3}
                        textColor="#333"
                        needleColor="#333"
                        style={{ width: '50%', height: '50%' }}
                        formatTextValue={(value) => `${data.compositeScore}/10`}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>

              {/* Code Quality and Design Metrics */}
              <Grid item xs={12} md={6}>
                <Card style={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h2">
                      <strong>Code Quality and Design Metrics</strong>
                    </Typography>
                    <Box sx={{ height: '100%', width: '100%' }}>
                      <Bar
                        data={{
                          labels: ['Design', 'Code Quality', 'Documentation', 'Performance', 'Test Coverage'],
                          datasets: [
                            {
                              label: 'Ratings',
                              data: [
                                data.designRating,
                                data.codeQualityRating,
                                data.documentationRating,
                                data.performanceRating,
                                data.testCoverageRating,
                              ],
                              backgroundColor: 'rgba(75, 192, 192, 0.6)',
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          plugins: {
                            legend: {
                              position: 'bottom',
                            },
                          },
                        }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {/* Architectural Quality and Best Practices */}
              <Grid item xs={12} md={6}>
                <Card style={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h2">
                      <strong>Architectural Quality and Best Practices</strong>
                    </Typography>
                    <Box sx={{ height: '100%', width: '100%' }}>
                      <Bar
                        data={{
                          labels: ['Security', 'Scalability', 'Reliability', 'Maintainability', 'Best Practices'],
                          datasets: [
                            {
                              label: 'Ratings',
                              data: [
                                data.securityRating,
                                data.scalabilityRating,
                                data.reliabilityRating,
                                data.maintainabilityRating,
                                data.bestPracticesRating,
                              ],
                              backgroundColor: 'rgba(75, 192, 192, 0.6)',
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          plugins: {
                            legend: {
                              position: 'bottom',
                            },
                          },
                        }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {/* Code Improvement Recommendations */}
              <Grid item xs={12} md={6}>
                <Card style={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h2">
                      <strong>Code Improvement Recommendations</strong>
                    </Typography>
                    <Typography variant="h3">
                      <strong>Areas of Improvement:</strong>
                    </Typography>
                    <ul>
                      {data.deepInsights.areasOfImprovement.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>

                    <Typography variant="h3">
                      <strong>Design Considerations:</strong>
                    </Typography>
                    <ul>
                      {data.deepInsights.designConsiderations.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>

                    <Typography variant="h3">
                      <strong>Testing Improvements:</strong>
                    </Typography>
                    <ul>
                      {data.deepInsights.testingImprovements.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              </Grid>

              {/* Impact Analysis and Scalability */}
              <Grid item xs={12} md={6}>
                <Card style={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="h2">
                      <strong>Impact Analysis and Scalability</strong>
                    </Typography>
                    <Typography variant="h3">
                      <strong>Potential Impacts:</strong>
                    </Typography>
                    <ul>
                      {Object.entries(data.deepInsights.potentialImpacts).map(([key, value], index) => (
                        <li key={index}>
                          <strong>{key}:</strong> {value}
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              </Grid>

              {/* Validation Feedback */}
              {data.validationFeedback?.validationFeedback &&
                Object.keys(data.validationFeedback.validationFeedback).length > 0 && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Card style={{ height: '100%' }}>
                        <CardContent>
                          <Typography variant="h2">
                            <strong>Rating Changes Since Last Scan</strong>
                          </Typography>
                          <ul>
                            {data.validationFeedback.validationFeedback.differencesInRatings.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Card style={{ height: '100%' }}>
                        <CardContent>
                          <Typography variant="h2">
                            <strong>Suggestions Based on Last Scan</strong>
                          </Typography>
                          <ul>
                            {data.validationFeedback.validationFeedback.suggestions.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </CardContent>
                      </Card>
                    </Grid>
                  </>
                )}
            </Grid>
          </Box>
        )}



      </div>
    </div>
  );
};

export default CodeInsightsPage;
